/** @format */
/* eslint-disable arrow-body-style */
import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import _isEqual from 'lodash/isEqual';
// import _pickBy from 'lodash/pickBy';
import { Stack, STACK_PRIVACY } from '@app/shared/models/stack.model';
import { ITEM_TYPE } from '@app/shared/models/layout.model';
import { List } from '@app/shared/models/list.model';
import * as ListActions from '@store/actions/lists.actions';
import * as ResetActions from '@store/actions/reset.actions';
import * as UserActions from '@store/actions/user.actions';
import * as StackActions from '@store/actions/stacks.actions';
import * as MystackActions from '@store/actions/mystack.actions';
import * as ProjectActions from '@store/actions/projects.actions';
import * as MemberActions from '@store/actions/members.actions';
import * as ClipActions from '@store/actions/clips.actions';
import * as ViewstateActions from '@store/actions/viewstate.actions';
// import { State as AppState } from '@store/reducers';
import { ProjectGroup } from '@store/selectors/projects.selectors';
import { getId as getClipId } from '@store/selectors/clips.selectors';
import { getId as getStackId } from './stacks.reducers';
import { FilterEntity, FilterEntityTypes } from './viewstate.reducers';
import { GUIDES_PROJECT } from '@app/app.config';
import { checkIsMember } from '@app/projects/shared/project.model';
import { environment } from 'src/environments/environment';

const DEBUG_LOGS = false;

export const featureKey = 'lists';

export { List } from '@app/shared/models/list.model';
// export interface List {
//   id: string;
//   itemIds: string[];
//   itemType?: ITEM_TYPE;
//   filters?: FilterEntity;
//   nextToken: string | null;
//   loading: boolean;
//   /**
//    * @note the defaultItemIds idea was valid, but is more complex than needed for initial version
//    * we can try to implement this at a later point; for now, kiss
//    */
//   // defaultItemIds?: string[]; // keep track of where we were before a filter query, to return to
//   // defaultNextToken?: string; // keep track of where we were before a filter query, to return to
// }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State extends EntityState<List> {
  // additional entity state properties
}

export const adapter: EntityAdapter<List> = createEntityAdapter<List>({
  selectId: (list) => list.id,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const getStackListsToModify = ({
  entities,
  projectId,
  stackId,
  featured = 0,
  isCollaborative = -1,
  stack,
  userId,
}: {
  entities: Dictionary<List>;
  itemType?: ITEM_TYPE;
  projectId: string;
  stackId: string;
  featured: number;
  isCollaborative?: number;
  stack?: Stack;
  userId?: string;
}): { listsWith; listsToAdd; listsToRemove; matchTypeAdd?; matchTypeRemove? } => {
  const id = getStackId(projectId, stackId);
  // check if stack.shares or stack.views realtime updates (not un/feature)
  if (stack && featured === 0 && (stack.shares || stack.views)) {
    // since we have shares or views here, and no feature, we have to assume that we should not modify the recent/featured lists
    return {
      listsWith: Object.values(entities)
        .filter((value) => value && value.itemType === ITEM_TYPE.Stacks)
        .filter((value) => value.itemIds.indexOf(id) > -1),
      listsToAdd: [],
      listsToRemove: [],
    };
  }

  // it changed, so find all lists containing
  const listsWith = Object.values(entities).filter(
    (value) => value && value.itemType === ITEM_TYPE.Stacks && value.itemIds.indexOf(id) > -1
  );

  // handle collab drafts if it was set to >0, else handle as normal stack
  if (stack?.isCollaborative > -1 && !stack.dtePublished && (!featured || featured < 1)) {
    const collabLists = Object.values(entities).filter(
      (value) => value?.itemType === ITEM_TYPE.Stacks && value?.filters?.isCollaborative > 0
    );
    const draftLists = Object.values(entities).filter(
      (value) =>
        value &&
        value.itemType === ITEM_TYPE.Stacks &&
        value.filters &&
        value.filters.type === FilterEntityTypes.StackDrafts
    );
    // const topDrawerList = draftLists.filter((v) => v.filters.isTopDrawer);
    // if isCollaborative == 0 also remove from the collab lists, but not mine
    const listsToRemove =
      isCollaborative === 0
        ? collabLists.filter((value) => value && value.filters && value.filters.userId !== userId)
        : [];
    const listsToAdd =
      isCollaborative === 0
        ? draftLists.filter(
            (value) =>
              value.itemIds.indexOf(id) < 0 && // lists not containing
              (value.filters.projectId === projectId || value.filters.userId === userId)
          )
        : collabLists.filter(
            (value) =>
              value &&
              value.itemIds.indexOf(id) < 0 && // lists not containing
              value.filters &&
              (value.filters.isTopDrawer || value.filters.projectId === projectId || value.filters.userId === userId)
          );
    DEBUG_LOGS &&
      console.warn('collab lists?', {
        stack,
        collabLists,
        listsWith,
        listsToAdd,
        listsToRemove,
      });
    return {
      listsWith,
      listsToAdd,
      listsToRemove,
    };
  }

  // handle featured
  const didFeature = featured > 0;
  // if didFeature, need to add to any "featuredStacks" lists, remove from recents
  // else add to recents, remove from featured
  const matchTypeAdd = didFeature ? FilterEntityTypes.Featured : FilterEntityTypes.Recent;
  const matchTypeRemove = didFeature ? FilterEntityTypes.Recent : FilterEntityTypes.Featured;
  const listsToRemove = listsWith.filter((value) => value && value.filters && value.filters.type === matchTypeRemove);
  // && value.filters.projectId === projectId
  const listsToAdd = Object.values(entities).filter(
    (value) =>
      value &&
      value.itemType === ITEM_TYPE.Stacks &&
      value.filters &&
      value.filters.projectId === projectId &&
      value.filters.type === matchTypeAdd &&
      value.itemIds.indexOf(id) < 0 // lists not containing
  );

  return {
    listsWith,
    listsToAdd,
    listsToRemove,
    matchTypeAdd,
    matchTypeRemove,
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCollabDraftLists = ({ entities, projectId, isCollaborative }) => {
  return Object.values(entities as Dictionary<List>).filter(
    (value) =>
      value &&
      value.itemType === ITEM_TYPE.Stacks &&
      value.filters &&
      value.filters.type === FilterEntityTypes.StackDrafts &&
      value.filters.isCollaborative &&
      value.filters.isCollaborative > 0 && //=== isCollaborative &&
      // if no projectId, it's my list
      (!value.filters.projectId || value.filters.projectId === projectId)
  );
};

const handleStackFeatured = ({ entities, projectId, stackId, featured, fromIdx = -1, toIdx = -1 }) => {
  const id = getStackId(projectId, stackId);
  const { listsWith, listsToAdd, listsToRemove, matchTypeAdd } = getStackListsToModify({
    entities,
    projectId,
    stackId,
    featured,
    stack: entities[id],
  });
  DEBUG_LOGS &&
    console.log(`[ListsStore] StackActions.handleFeatured`, {
      featured,
      fromIdx,
      toIdx,
      listsWith,
      listsToAdd,
      listsToRemove,
    });

  const updates = listsToRemove.map((list) => ({
    id: list.id,
    changes: { itemIds: list.itemIds.filter((itemId) => itemId && itemId !== id) },
  }));
  // now that it was removed from lists, add to featured where it fits the query
  updates.push(
    ...listsToAdd.map((list) => {
      // v1 - if it went from or to an index that's truthy
      if (fromIdx || toIdx || toIdx === 0) {
        if (list.itemIds.length > toIdx) {
          // it's been dragged to new featured weight, move it in list
          const itemIds = list.itemIds.filter((d) => d && d !== id);
          itemIds.splice(toIdx || 0, 0, id);
          // movedItemIds.splice(toIdx, 0, movedItemIds.splice(fromIdx || 0, 1)[0]);
          console.log({
            itemIds,
          });
          return {
            id: list.id,
            changes: { itemIds },
          };
        } else {
          console.warn(`setFeatured list.itemIds.length (${list.itemIds.length}) <= toIdx (${toIdx})`);
        }
      }
      // // v2 the index change really isn't the interesting data point here.. it's that the sort should be Featured!
      // const movedItemIds = [...list.itemIds.filter(d => d && d !== id), id]; // add it uniquely
      // // now we need the entities of the stacks! ugg... wrong.
      // movedItemIds.sort(sortFeatured);
      return {
        id: list.id,
        // if it's a new featured, add to the end, else it's recent so put at front
        changes:
          matchTypeAdd === FilterEntityTypes.Featured
            ? { itemIds: [...list.itemIds.filter((d) => d !== id), id] }
            : { itemIds: [id, ...list.itemIds.filter((d) => d !== id)] },
      };
    })
  );

  return updates;
};

function upsertLoadSuccessList(
  state: State,
  listId: string,
  itemType: ITEM_TYPE,
  isLoadMore: boolean,
  filters: FilterEntity,
  nextToken: string,
  fetchedItemIds: string[]
) {
  const entity: List = state.entities[listId];
  // check if we should take the itemIds from before, as it is the case with appended items (isLoadMore)
  const itemIds = (isLoadMore || filters?.isTopDrawer) && entity && Array.isArray(entity.itemIds) ? entity.itemIds : [];
  // and, just take the uniques
  const newItemIds = fetchedItemIds.filter(
    (id, index) => !itemIds.includes(id) && index === fetchedItemIds.indexOf(id)
  );

  // check if this is topnavdrawer and there's a selected that's not included
  // const topDrawerList = draftLists.filter((v) => v.filters.isTopDrawer);

  /**
   * @note the makeDefault idea was valid, but is more complex than needed for initial version
   * we can try to implement this at a later point; for now, kiss
   */
  // if this is new load, and there's filters, make the current ids the default
  // const makeDefault
  //   = entity && !isLoadMore &&
  //     filters &&
  //     (filters.q || filters.tags || filters.users) &&
  //     (!Array.isArray(entity.defaultItemIds) || entity.defaultItemIds.length < 1)

  // console.warn(`[ListsStore] (${listId}) upsertLoadSuccessList makeDefault:`, {
  //   makeDefault,
  //   test: {
  //     notIsLoadMore: !isLoadMore,
  //     filter: filters && filters.q ? filters.q : false,
  //     entity,
  //     defaultItemIds: entity && entity.defaultItemIds ? entity.defaultItemIds : null,
  //   },
  // });

  const list: List = {
    id: listId,
    itemIds: itemIds.concat(newItemIds),
    filters,
    itemType,
    nextToken,
    loading: false,
    // defaultItemIds: makeDefault ? entity.itemIds : [],
    // defaultNextToken: makeDefault ? entity.nextToken : '',
  };
  DEBUG_LOGS &&
    console.log(`[ListsStore] (${listId}) upsertLoadSuccessList`, {
      list,
      x_debug: { itemIds, newItemIds, fetchedItemIds, isLoadMore, itemType },
    });
  return adapter.upsertOne(list, state);
}

const listReducer = createReducer(
  initialState,

  /**
   * UpdateLists
   */
  on(ListActions.updateLists, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),

  /**
   * On Set Filter, find Lists that use that FilterId
   * reseting Items on every change
   */
  on(ViewstateActions.setFilter, (state, { filter }) => {
    const lists = Object.values(state.entities).filter(
      (value) => value && value.filters && value.filters.id && value.filters.id === filter.id
    );
    // if the filter has changed, reset
    const updates = lists
      .filter((list) => list && list.filters && list.filters.q !== filter.q)
      .map((list) => ({
        id: list.id,
        changes: { loading: true, itemIds: [], nextToken: null },
      }));
    DEBUG_LOGS && console.log(`[ListsStore] setFilter - reset matching filters...`, { lists, updates, filter });
    return adapter.updateMany(updates, state);
  }),

  /*
    STACKS
  */
  on(
    // on addDraft, add the stack to Draft Lists
    StackActions.addDraft,
    (state, { stack }) => {
      const id = getStackId(stack.projectId, stack.stackId);

      const listsToAdd = Object.values(state.entities).filter(
        (value) =>
          value &&
          value.itemType === ITEM_TYPE.Stacks &&
          value.filters &&
          value.filters.type === FilterEntityTypes.StackDrafts &&
          (stack.isCollaborative ? value.filters.isCollaborative > 0 : !value.filters.isCollaborative)
      );

      const updates = listsToAdd.map((list) => ({
        id: list.id,
        // prepend to list (most recent) and filter out so it only exists once
        changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
      }));

      return adapter.updateMany(updates, state);
    }
  ),

  // on(StackActions.add, (state, { stacks }) => {
  //   // on add, we could add the stacks.forEach to lists (might have just been published)
  //   // ... instead we will rely on the subUpdate to update the lists
  //   return state
  // }),

  /**
   * reset the itemIds on new load
   * 2022-09-02 jd updated logic to check ListState
   *  if the filters match && there's no nextToken, then done
   */
  on(StackActions.loadFilteredStacks, (state, { listId, filters }) => {
    const entity = state.entities[listId];
    let itemIds = [],
      nextToken = null,
      loading = true;

    if (entity && entity.filters && _isEqual(filters, entity.filters)) {
      // the filters match existing entity, we've loaded previously..
      itemIds = Array.isArray(entity.itemIds) ? entity.itemIds : [];
      nextToken = entity.nextToken;
      // tell StackEffects to load more..
      loading = nextToken || itemIds.length < 1;
    }
    DEBUG_LOGS &&
      console.log(`[ListStore] StackActions.loadFilteredStacks:`, {
        listId,
        filters,
        entity: state.entities[listId],
        loading,
        itemIds,
        nextToken: !!nextToken,
      });
    return adapter.upsertOne(
      {
        id: listId,
        loading,
        itemType: ITEM_TYPE.Stacks,
        filters,
        itemIds,
        nextToken,
      },
      state
    );
  }),
  on(StackActions.loadMoreFilteredStacks, (state, { listId }) => {
    return adapter.updateOne({ id: listId, changes: { loading: true } }, state);
  }),
  on(StackActions.loadSuccess, (state, { stacks, listId, nextToken, filters, isLoadMore }) => {
    const fetchedItemIds = stacks.map((stack) => getStackId(stack.projectId, stack.stackId));
    // console.log(`[ListsStore] stacks.loadSuccess`, { stacks, listId, fetchedItemIds });
    return upsertLoadSuccessList(state, listId, ITEM_TYPE.Stacks, isLoadMore, filters, nextToken, fetchedItemIds);
  }),
  on(StackActions.noMoreToLoad, (state, { listId }) => {
    return adapter.updateOne({ id: listId, changes: { loading: false, nextToken: null } }, state);
  }),
  on(StackActions.deleteStack, (state, { stack }) => {
    const id = getStackId(stack.projectId, stack.stackId);
    DEBUG_LOGS && console.log(`[ListsStore] StackActions.deleteStack`, { stack, id });
    const lists = Object.values(state.entities)
      .filter((value) => value && value.itemType === ITEM_TYPE.Stacks)
      .filter((value) => value.itemIds.indexOf(id) > -1);
    const updates = lists.map((list) => ({
      id: list.id,
      changes: { itemIds: list.itemIds.filter((itemId) => itemId && itemId !== id) },
    }));
    DEBUG_LOGS && console.log(`[ListsStore] StackActions.deleteStack id: '${id}'`, { lists, updates });
    return adapter.updateMany(updates, state);
  }),

  on(StackActions.selectIdEdit, (state, { projectId, stackId }) => {
    // check if this is editable and not currently in my topnav drawer
    if (!state.entities) {
      console.warn('no entities');
      return state;
    }
    const topDrawerLists = Object.values(state.entities).filter(
      (value) =>
        value &&
        value.itemType === ITEM_TYPE.Stacks &&
        value.filters &&
        // value.filters.type === FilterEntityTypes.StackDrafts &&
        value.filters.isTopDrawer
    );
    const id = getStackId(projectId, stackId);
    const updates = topDrawerLists.map((list) => ({
      id: list.id,
      // prepend to list (most recent) and filter out so it only exists once
      changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
    }));
    if (updates.length > 0) {
      return adapter.updateMany(updates, state);
    } else {
      return state;
    }
  }),

  on(StackActions.setFeatured, (state, { projectId, stackId, featured, isApproved, fromIdx, toIdx }) => {
    const updates = handleStackFeatured({ projectId, stackId, fromIdx, toIdx, featured, entities: state.entities });
    DEBUG_LOGS &&
      console.log(`[ListsStore] StackActions.setFeatured`, {
        updates,
        projectId,
        stackId,
        featured,
        isApproved,
        fromIdx,
        toIdx,
      });
    return adapter.updateMany(updates, state);
  }),

  on(StackActions.setCollaborative, (state, { projectId, stackId, isCollaborative }) => {
    const id = getStackId(projectId, stackId);
    const updates = [];
    const collabDraftLists = getCollabDraftLists({ entities: state.entities, projectId, isCollaborative });
    const draftLists = Object.values(state.entities).filter(
      (value) =>
        value &&
        value.itemType === ITEM_TYPE.Stacks &&
        value.filters &&
        value.filters.type === FilterEntityTypes.StackDrafts &&
        !value.filters.isCollaborative &&
        // if no projectId, it's my list
        (!value.filters.projectId || value.filters.projectId === projectId)
    );
    if (isCollaborative > 0) {
      // add to collab lists, remove from drafts (but not your drawer)
      updates.push(
        ...collabDraftLists.map((list) => ({
          id: list.id,
          changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
        }))
      );
      updates.push(
        ...draftLists
          .filter(
            (value) =>
              // but not your drawer
              !value.filters.isTopDrawer
          )
          .map((list) => ({
            id: list.id,
            changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
          }))
      );
    } else {
      // add to drafts lists, remove from collabs
      updates.push(
        ...collabDraftLists.map((list) => ({
          id: list.id,
          changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
        }))
      );
      updates.push(
        ...draftLists.map((list) => ({
          id: list.id,
          changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
        }))
      );
    }
    DEBUG_LOGS &&
      console.log(`[ListsStore] StackActions.setCollaborative`, {
        updates,
        draftLists,
        collabDraftLists,
        projectId,
        stackId,
        isCollaborative,
      });
    return adapter.updateMany(updates, state);
  }),

  on(StackActions.setCollaborative, (state, { projectId, stackId, isCollaborative }) => {
    const id = getStackId(projectId, stackId);
    const updates = [];
    const collabDraftLists = getCollabDraftLists({ entities: state.entities, projectId, isCollaborative });
    if (isCollaborative > 0) {
      updates.push(...collabDraftLists.map(list => ({
        id: list.id,
        changes: { itemIds: [ id, ...list.itemIds.filter((itemId) => itemId && itemId !== id) ] },
      })));
    } else {
      updates.push(...collabDraftLists.map(list => ({
        id: list.id,
        changes: { itemIds: [ ...list.itemIds.filter((itemId) => itemId && itemId !== id) ] },
      })));
    }
    DEBUG_LOGS && console.log(`[ListsStore] StackActions.setCollaborative`, { updates, collabDraftLists, projectId, stackId, isCollaborative })
    return adapter.updateMany(updates, state);
  }),
  // StackActions.setFeatured has an API Effect, this action is less invasive
  // on(ListActions.updateIds, (state, { ids }) => {
  //   const updates = handleFeatured({ projectId, stackId, toIdx, featured, entities: state.entities });
  //   DEBUG_LOGS && console.log(`[ListsStore] ListActions.setStackFeatured`, { updates, projectId, stackId, featured, toIdx })
  //   return adapter.updateMany(updates, state);
  // }),

  /**
   * on Stack Load Fail, remove that stack from any lists
   *
   */
  on(StackActions.loadFail, (state, action) => {
    // const { error } = action;
    console.warn(`[ListsStore] Stack load fail:`, action);
    const id = getStackId(action.projectId, action.stackId);
    const listsWith = Object.values(state.entities).filter(
      (value) => value && value.itemType === ITEM_TYPE.Stacks && value.itemIds.indexOf(id) > -1
    );
    const updates = listsWith.map((list) => ({
      id: list.id,
      changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
    }));
    return adapter.updateMany(updates, state);
  }),
  /**
   * On Mystack.publish - prepend it any lists for this ProjectId, Recent
   * Also MINE for Studio
   */
  on(MystackActions.publishStack, (state, { stack }) => {
    const id = getStackId(stack.projectId, stack.stackId);
    DEBUG_LOGS && console.log(`[ListsStore] MystackActions.publishStack`, { stack, id });
    const isPublic = !stack.private && stack.privacy === STACK_PRIVACY.PUBLIC;

    // lists of RECENT STACKS for this projectId , if STACK.PUBLIC
    const projectListsToAdd = isPublic
      ? Object.values(state.entities).filter(
          (value) =>
            value &&
            value.itemType === ITEM_TYPE.Stacks &&
            value.filters &&
            value.filters.type === FilterEntityTypes.Recent &&
            value.filters.projectId === stack.projectId
        )
      : [];
    // lists of STUDIO STACKS
    const studioListsToAdd = Object.values(state.entities).filter(
      (value) =>
        value &&
        value.itemType === ITEM_TYPE.Stacks &&
        value.filters &&
        value.filters.type === FilterEntityTypes.Recent &&
        value.filters.userId === stack.userId &&
        value.filters.isPrivate === !isPublic
    );

    const updates = [...projectListsToAdd, ...studioListsToAdd].map((list) => ({
      id: list.id,
      // prepend to list (most recent) and filter out so it only exists once
      changes: { itemIds: [id, ...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
    }));

    // remove from draft lists
    const listsToRemove = Object.values(state.entities).filter(
      (value) =>
        value &&
        value.itemType === ITEM_TYPE.Stacks &&
        value.filters &&
        value.filters.type === FilterEntityTypes.StackDrafts
      // && value.filters.userId === stack.userId
    );

    updates.push(
      ...listsToRemove.map((list) => ({
        id: list.id,
        // remove from all draft lists (filter out)
        changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
      }))
    );
    DEBUG_LOGS && console.log(`[ListsStore] MystackActions.publishStack id: '${id}'`, { projectListsToAdd, updates });
    return adapter.updateMany(updates, state);
  }),

  /*
    PROJECTS
  */
  on(ProjectActions.loadSuccess, (state, { projects, listId, nextToken, filters, isLoadMore }) => {
    if (!listId) {
      // projects not in a list
      return state;
    }
    const fetchedItemIds = projects.map((project) => project.id);
    // console.log(`[ListsStore] projects.loadSuccess`, { projects, listId, nextToken, filters, isLoadMore, fetchedItemIds });
    return upsertLoadSuccessList(state, listId, ITEM_TYPE.Projects, isLoadMore, filters, nextToken, fetchedItemIds);
  }),
  // not needed right now, right?
  // on(ProjectActions.selectById, (state, { id }) => {
  //   const lists = Object.values(state.entities)
  //     .filter((value) => value && value.filters && value.filters.projectId && value.filters.projectId === id);
  //   const listIds = lists.map(list => list.id);
  //   console.warn(`[ListsStore] ProjectActions.selectById`, { id, lists, listIds });
  //   return state;
  // }),

  /*
    CLIPS
  */
  on(ClipActions.loadFilteredClips, (state, { listId, filters }) => {
    // reset the itemIds on new load
    return adapter.upsertOne(
      { id: listId, loading: true, itemType: ITEM_TYPE.Clips, filters, itemIds: [], nextToken: null },
      state
    );
  }),
  on(ClipActions.loadMoreFilteredClips, (state, { listId }) => {
    return adapter.updateOne({ id: listId, changes: { loading: true } }, state);
  }),
  on(ClipActions.noMoreToLoad, (state, { listId }) => {
    return adapter.updateOne({ id: listId, changes: { loading: false, nextToken: null } }, state);
  }),
  on(ClipActions.loadSuccess, (state, { clips, listId, nextToken, filters, isLoadMore }) => {
    const fetchedItemIds = clips.map((clip) => getClipId(clip.projectId, clip.id));
    DEBUG_LOGS && console.log(`[ListsStore] clips.loadSuccess`, { clips, listId, fetchedItemIds });
    return upsertLoadSuccessList(state, listId, ITEM_TYPE.Clips, isLoadMore, filters, nextToken, fetchedItemIds);
  }),
  on(ClipActions.deleteClip, (state, { clip }) => {
    DEBUG_LOGS && console.log(`[ListsStore] ClipActions.deleteClip`, { clip });
    const lists = Object.values(state.entities)
      .filter((value) => value && value.itemType === ITEM_TYPE.Clips)
      .filter((value) => value.itemIds.indexOf(clip.id) > -1);
    const updates = lists.map((list) => ({
      id: list.id,
      changes: { itemIds: list.itemIds.filter((id) => id && id !== clip.id) },
    }));
    DEBUG_LOGS && console.log(`[ListsStore] ClipActions.deleteClip id: '${clip.id}'`, { lists, updates });
    return adapter.updateMany(updates, state);
  }),

  /**
   * User Auth should modify content, just clear for rebuild
   */
  on(ResetActions.resetStoreOnLogout, UserActions.logout, UserActions.loginSuccess, (state) => {
    // get all lists that are related to any project (affected by member state), except GUIDES_PROJECT
    // also my_projects & my_stack_drafts via the filters.userId existing
    const listsToDelete = Object.values(state.entities).filter(
      (value) =>
        value &&
        value.filters &&
        ((value.filters.projectId && value.filters.projectId !== GUIDES_PROJECT) || value.filters.userId)
    );
    const listIds = listsToDelete.map((list) => list.id);
    DEBUG_LOGS && console.log(`[ListsStore] UserActions.auth`, { listIds, listsToDelete });
    return adapter.removeMany(listIds, state);
  }),

  /**
   * deleteList
   */
  on(ListActions.deleteList, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  /**
   * deleteLists
   */
  on(ListActions.deleteLists, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),

  /**
   * Real-time-data
   */
  on(ProjectActions.subUpdate, (state, { project, userId }) => {
    if (project && project.id) {
      // check if i am a member
      if (checkIsMember(userId, project)) {
        const lists = Object.values(state.entities).filter(
          (value) => value && value.itemType === ITEM_TYPE.Projects && value.id === ProjectGroup.Mine
        );

        const updates = lists.map((list) => ({
          id: list.id,
          changes: { itemIds: [project.id, ...list.itemIds.filter((itemId) => itemId && itemId !== project.id)] },
        }));
        DEBUG_LOGS && console.log(`[ListsStore] ProjectActions.subUpdate id: '${project.id}'`, { lists, updates });
        return adapter.updateMany(updates, state);
      } else {
        return state;
      }
    } else {
      // ignoring items we don't care about..
      return state;
    }
  }),
  on(MemberActions.subUpdate, (state, { memberProject, userId }) => {
    // check if it's mine
    if (memberProject && memberProject.userId === userId && memberProject.projectId) {
      const projectId = memberProject.projectId;
      const updates = [];
      const lists = Object.values(state.entities).filter(
        (value) => value && value.itemType === ITEM_TYPE.Projects && value.id === ProjectGroup.Mine
      );

      updates.push(
        ...lists.map((list) => {
          if (!memberProject.isActive) {
            // remove from my lists
            return {
              id: list.id,
              changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== projectId)] },
            };
          } else if (list.itemIds.indexOf(projectId) < 0) {
            // if not in my lists, add it
            return {
              id: list.id,
              changes: { itemIds: [projectId, ...list.itemIds.filter((itemId) => itemId && itemId !== projectId)] },
            };
          } else {
            // it already exists...
            return {};
          }
        })
      );
      DEBUG_LOGS &&
        console.log(`[ListsStore] MemberActions.subUpdate id: '${memberProject.projectId}'`, { lists, updates });
      return adapter.updateMany(updates, state);
    } else {
      // ignoring items we don't care about..
      return state;
    }
  }),
  on(ClipActions.subUpdate, (state, { clip, userId }) => {
    if (clip && clip.projectId) {
      const clipId = getClipId(clip.projectId, clip.id);
      // also check if this stack is private and not mine - remove from lists
      const lists = Object.values(state.entities)
        .filter((value) => value && value.itemType === ITEM_TYPE.Clips)
        .filter((value) => value && value.filters && value.filters.projectId === clip.projectId);
      const updates = lists.map((list) => ({
        id: list.id,
        changes: { itemIds: [clipId, ...list.itemIds.filter((itemId) => itemId && itemId !== clipId)] },
      }));
      DEBUG_LOGS && console.log(`[ListsStore] ClipActions.subUpdate id: '${clipId}'`, { lists, updates, userId });
      return adapter.updateMany(updates, state);
    } else {
      // ignoring clips we don't care about.. - this should be handled before the action is sent, no?
      console.log(`subUpdate ignoring no projectId:`, clip);
      return state;
    }
  }),

  on(StackActions.subUpdate, (state, { stack, userId }) => {
    if (stack && stack.projectId) {
      const id = getStackId(stack.projectId, stack.stackId);
      const { listsWith, listsToAdd, listsToRemove } = getStackListsToModify({
        entities: state.entities,
        projectId: stack.projectId,
        stackId: stack.stackId,
        featured: stack.featured,
        isCollaborative: stack.isCollaborative,
        stack,
        userId,
      });
      const draftLists = listsWith.filter(
        (value) => value && value.filters && value.filters.type === FilterEntityTypes.StackDrafts
      );
      const updates = [];

      // handling Stack Drafts in ListEffects
      if (stack.dtePublished || stack.shareUrl) {
        // remove from any drafts lists, it was published
        updates.push(
          ...draftLists.map((list) => ({
            id: list.id,
            changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
          }))
        );
      }

      updates.push(
        ...listsToRemove.map((list) => ({
          id: list.id,
          changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
        }))
      );
      if (stack.private || stack.privacy === STACK_PRIVACY.UNLISTED || stack.privacy === STACK_PRIVACY.PRIVATE) {
        // check if this stack is private and not mine - remove from lists
        // but not from my stack drafts
        updates.push(
          ...listsWith
            .filter((list) => list && !(list.filter && list.filter.type === FilterEntityTypes.StackDrafts))
            .map((list) => ({
              id: list.id,
              changes: { itemIds: [...list.itemIds.filter((itemId) => itemId && itemId !== id)] },
            }))
        );
      } else if (!stack.featured && (stack.isApproved || stack.isApproved === false)) {
        // add it to recent
        DEBUG_LOGS && console.log(`add it to recent`, listsToAdd);
        updates.push(
          ...listsToAdd.map((list) => ({
            id: list.id,
            changes: {
              itemIds: [id, ...list.itemIds],
            },
          }))
        );
        // } else if (stack.userId !== userId && (stack.private || stack.privacy !== STACK_PRIVACY.PUBLIC)) {
        //   // check if this stack is private and not mine - remove from lists
        //   updates.push(...listsWith.map(list => ({
        //     id: list.id,
        //     changes: { itemIds: [ ...list.itemIds.filter((itemId) => itemId && itemId !== id) ] },
        //   })));
      } else if (stack.featured && stack.featured > 0) {
        // if featured was updated, this is not a recent stack - will be handled in ListEffect (we need the entities to sort)
        // updates.push(...listsToAdd.map(list => ({
        //   id: list.id,
        //   // add it to the end
        //   changes: { itemIds: [ ...list.itemIds.filter((itemId) => itemId && itemId !== id), id ] },
        // })));
      } else if (stack.dtePublished || stack.shareUrl) {
        updates.push(
          ...listsToAdd
            .filter((list) => !list.itemIds.includes(id))
            .map((list) => ({
              id: list.id,
              // add it to the start of the list (recent)
              changes: { itemIds: [id, ...list.itemIds] },
            }))
        );
      } else if (draftLists.length < 1) {
        !environment.production && console.log(`Here we can add to ProjectDrafts?`);
        updates.push(
          ...listsToAdd.map((list) => ({
            id: list.id,
            changes: {
              itemIds: [id, ...list.itemIds],
            },
          }))
        );
      } else {
        // nothing to do here..
        // note: stack.isCollaborative is handled in lists.effects.subUpdate$
      }
      DEBUG_LOGS &&
        console.warn(`[ListsStore] StackActions.subUpdate id: '${id}'`, {
          updates,
          listsWith,
          listsToAdd,
          listsToRemove,
        });
      return adapter.updateMany(updates, state);
    } else {
      // ignoring clips we don't care about.. - this should be handled before the action is sent, no?
      console.log(`subUpdate ignoring no projectId:`, stack);
      return state;
    }
  }),

  /**
   * @deprecated
   */
  on(ClipActions.loadClipsByProjectId, (state, { listId }) => {
    // DEBUG_LOGS &&
    console.warn(`[ListsStore] DEPRECATED! clips.loadClipsByProjectId`, { listId });
    // return adapter.updateOne({ id: listId, changes: { loading: true } }, state);
    return state;
  })
);

export function reducer(state: State | undefined, action: Action) {
  return listReducer(state, action);
}

export const {
  /** dictionary of entities */
  selectEntities,
  // selectIds,
} = adapter.getSelectors();

// selectors moved to selectors.ts file

// export const selectListsState = createFeatureSelector<State>(featureKey);

// // unable to get getSelectors.selectEntities to work in StackEffects? it's a Dict
// export const selectListEntities = createSelector(
//   selectListsState,
//   (state: State) => state.entities
// );

// /**
//  * Get By ID - wrap the selector inside a factory function to create different instances of the selector
//  * https://ngrx.io/guide/store/selectors#using-selectors-with-props
//  *
//  * USAGE:
//  * this.store.select(selectList(), { id: 'list-id' });
//  */
// export const selectList = () => createSelector(selectEntities, (entities, props) => entities[props.id] || {});

// /**
//  * Select STACK ListItems
//  * specifically, since we need the StackEntities here
//  *
//  * USAGE:
//  * this.store.select(selectStackListItems(), { id: 'list-id' });
//  */
// export const selectStackListItems = () =>
//   createSelector(selectEntities, selectStackEntities, (listEntities, stackEntities, props) => {
//     return listEntities[props.id] ? listEntities[props.id].itemIds.map((id) => stackEntities[id]) : undefined;
//   });

// export const selectProjectListItems = () =>
//   createSelector(selectEntities, selectProjectEntities, (listEntities, projectEntities, props) => {
//     return listEntities[props.id] ? listEntities[props.id].itemIds.map((id) => projectEntities[id]) : undefined;
//   });

// export const selectClipListItems = () =>
//   createSelector(selectEntities, selectClipEntities, (listEntities, clipEntities, props) => {
//     return listEntities[props.id] ? listEntities[props.id].itemIds.map((id) => clipEntities[id]) : undefined;
//   });

// /**
//  * select the Stack Drafts
//  */
// // export const selectListDraftEntities = createSelector(
// //   selectListsState,
// //   (state: State) => state.entities
// // );
// /** get the drafts only */
// export const selectStackDraftListItems = () =>
//   createSelector(selectEntities, selectStackEntities, (listEntities, stackEntities, props) => {
//     if (!listEntities[props.id]) {
//       return undefined;
//     }
//     return listEntities[props.id].itemIds.map((id) => stackEntities[id]).filter(stack => stack && !stack.dtePublished);
//     // return listEntities[props.id] ? listEntities[props.id].itemIds.map((id) => stackEntities[id]) : undefined;
// });
